
/*列表上方操作按钮区域*/
.ant-card-body .table-operator {
  margin-bottom: 18px;
}
/** Button按钮间距 */
.table-operator .ant-btn {
  margin-right: 6px
}
/*列表td的padding设置 可以控制列表大小*/
.ant-table-tbody .ant-table-row td {
  padding-top: 15px;
  padding-bottom: 15px;
}

/*列表页面弹出modal*/
.ant-modal-cust-warp {
  height: 100%
}

/*弹出modal Y轴滚动条*/
.ant-modal-cust-warp .ant-modal-body {
  height: calc(100% - 110px) !important;
  overflow-y: auto
}

/*弹出modal 先有content后有body 故滚动条控制在body上*/
.ant-modal-cust-warp .ant-modal-content {
  height: 90% !important;
  overflow-y: hidden
}
/*列表中有图片的加这个样式 参考用户管理*/
.anty-img-wrap {
//   height: 25px;
  position: relative;
}
.anty-img-wrap > img {
  max-height: 100%;
}
/*列表中范围查询样式*/
.query-group-cust{width: calc(50% - 10px)}
.query-group-split-cust:before{content:"~";width: 20px;display: inline-block;text-align: center}


/*erp风格子表外框padding设置*/
.ant-card-wider-padding.cust-erp-sub-tab>.ant-card-body{padding:5px 12px}


.ant-table-body, .ant-table-content {
  overflow-x: auto;
}

.ant_preview_single_img .ant-modal-content {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-confirm-btns {
    display: none;
  }
  .ant-modal-confirm-content {
    margin-top: 0;
  }
} 

// ant-tabs
.ant-tabs.ant-tabs-top {
    .ant-tabs-tab {
        padding: 10px 12px 8px;
        & + .ant-tabs-tab {
            margin-left: 20px;
        }
    }
}
.ant_tabs_top_align_right {
    .ant-tabs.ant-tabs-top {
        .ant-tabs-nav-wrap {
            justify-content: flex-end;
        }
    }
}

body .ant-drawer.ant-drawer-right .ant-drawer-body {
  padding-bottom: 53px;
}

// modal弹层设置body最大高度
.custom_modal_class .ant-modal-body,
.ant-modal-wrap.ant-modal-centered .ant-modal-body {
  max-height: calc(80vh - 110px);
  overflow: hidden auto;
}

// react start
#root {
    width: 100%;
    height: 100vh;
}
div[transition-component] {
    will-change: opacity;

    // 透明渐入渐隐
	&.opacity-appear {
		opacity: 0.02;
	}
	&.opacity-appear.opacity-appear-active {
		transition: opacity .6s;
		opacity: 1;
	}
    // 从右边往左边的方向滑入
    &.slider-left-appear {
        left: 100%;
    }
    &.slider-left-appear.slider-left-appear-active {
		transition: left .3s;
		left: 0;
	}
    // 从左边往右边的方向滑入
    &.slider-right-appear {
        left: -100%;
    }
    &.slider-right-appear.slider-right-appear-active {
		transition: left .3s;
		left: 0;
	}
}
.page_inner_module {
    margin-bottom: 36px;
}
.custom_scrollbar {
    $scrollBarSize: 10px;
    /* 定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    &::-webkit-scrollbar {
        width: $scrollBarSize;
        height: $scrollBarSize;
        background-color: transparent;
    }

    &_none::-webkit-scrollbar {
        display: none;
    }

    /* 兼容IE */
    -ms-overflow-style: none;
    -ms-scroll-chaining: chained;
    -ms-content-zooming: zoom;
    -ms-scroll-rails: none;
    -ms-content-zoom-limit-min: 100%;
    -ms-content-zoom-limit-max: 500%;
    -ms-scroll-snap-type: proximity;
    -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);

    /* 定义滚动条轨道 */
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* 定义滑块 */
    &::-webkit-scrollbar-thumb {
        border-radius: $scrollBarSize;
        background-color: #ccc;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);

        &:hover {
            background-color: #bbb;
        }

        &:active {
            background-color: #b3b3b3;
        }
    }
}
// react end
