/* common */
input, textarea, keygen, select, button {   
    outline: none;
    border: 0 none;
}
a, abbr, acronym, address, applet, audio, b, big, body, center, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, form, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, object, ol, p, pre, s, samp, small, span, strike, strong, sub, sup, time, tt, u, ul, var, video, input, textarea, keygen, select, button, section, article, nav, main, header, footer, option {
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    box-sizing: border-box;
}

a img {
    border: 0 none;
}

ol, ul, li {
    list-style: none;
}

a {
    -webkit-tap-highlight-color: transparent;
    -webkit-focus-ring-color: transparent;
    -moz-tap-highlight-color: transparent;
    -moz-focus-ring-color: transparent;
    color: #1890ff;
}
a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}


table {
    border-collapse: collapse;
    border-spacing: 0;
}

/*
语法：
ime-mode : auto | active | inactive | disabled 
参数：
auto : 默认值。不影响全角半角的输入。与不指定 ime-mode 属性时相同 
active : 当焦点移到该控件时，自动激活本地语言输入法并且是全角状态。用户可以改成半角
inactive : 当焦点移到该控件时，自动激活非本地语言输入法但是半角状态。用户可以改成全角
disabled : 完全禁止本地语言输入法，用户也不能修改
说明：
如果想让用户只输入半角字符，可以把ime-mode设成disabled；
如果想让用户默认输入半角字符，可以把ime-mode设成inactive；
如果想让用户默认输入全角字符，可以把ime-mode设成active； 
*/
.ime-disabled {
    ime-mode: disabled;
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
    width: 100%;
    font-family: -apple-system, "PingFang SC Medium", "苹方", "Microsoft YaHei", "微软雅黑", "Source Han Sans CN Medium", "思源黑体", "Segoe UI", "Helvetica Neue", Helvetica, "Hiragino Sans GB", Arial, sans-serif !important;
    line-height: 1.5 !important;
}
body {
    color: #333;
    font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}
.clearfix:after{
	content:".";
	display:block;
	height:0;
	clear:both;
	visibility:hidden;
	overflow: hidden;
}
.clearfix {
	zoom: 1;
}
.tc {
	text-align: center;
}
.tl {
    text-align: left;
}
.tr {
	text-align: right;
}
.fl {
	float: left;
}

.fr {
	float: right;
}
.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.ellipsis_line_2, .ellipsis-line-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.ellipsis_line_3, .ellipsis-line-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.wid100 {
	width: 100%;
}
.hei100 {
	height: 100%;
}
.wh100 {
	width: 100%;
	height: 100%;
}
.max_wid100 {
	max-width: 100%;
}
.max_hei100 {
	max-height: 100%;
}
.posfix {
	position: fixed;
}
.posrel {
	position: relative;
}
.posabs {
	position: absolute;
}
.z_index_99 {
    z-index: 99;
}
.z_index_max {
    z-index: 100000000;
}
.v_center {
	vertical-align: middle;
}
.center_middle {
	position: absolute;
	/* left: 50%;
	top: 50%;
  transform: translate(-50%, -50%); */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.none {
  display: none !important;
}
.select_none {
	user-select: none;
}
.line_through {
  text-decoration: line-through;
}
.white {
	color: #fff !important;
}
.bgwhite, .bg_white {
	background: #fff;
}
.black {
    color: #000;
}
.bgblack, .bg_black {
    background: #000;
}
.transparent {
	background: transparent;
}
.bold {
	font-weight: 700;
}
.overhid {
	overflow: hidden;
}
.overauto {
	overflow: auto;
}
.lt0 {
	left: 0;
	top: 0;
}
.rt0 {
	right: 0;
	top: 0;
}
.lb0 {
	left: 0;
	bottom: 0;
}
.rb0 {
	right: 0;
	bottom: 0;
}
.pointer {
	cursor: pointer;
}
.cursor_default {
	cursor: default !important;
}
.blk {
	display: block;
}
.il {
	display: inline;
}
.ilblk {
	display: inline-block;
}
.nowrap {
	white-space: nowrap;
}
.color_999 {
	color: #999;
}
.color_666 {
	color: #666;
}
.color_333 {
	color: #333;
}
.circle {
    border-radius: 50%;
}
.icon_svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.size_24 {
    font-size: 24px !important;
}
.biger_size {
  font-size: 22px !important;
}
.big_size {
  font-size: 20px !important;
}
.large_size {
  font-size: 18px !important;
}
.normal_size {
  font-size: 16px !important;
}
.medium_size {
  font-size: 14px !important;
}
.mini_size {
  font-size: 12px !important;
}
.pt_20 {
    padding-top: 20px !important;
}
.pb_20 {
    padding-bottom: 20px !important;
}
.pl_20 {
    padding-left: 20px !important;
}
.pr_20 {
    padding-right: 20px !important;
}
.mt_20 {
    margin-top: 20px !important;
}
.mb_20 {
    margin-bottom: 20px !important;
}
.ml_20 {
    margin-left: 20px !important;
}
.mr_20 {
    margin-right: 20px !important;
}
.pt_24 {
    padding-top: 24px !important;
}
.pb_24 {
    padding-bottom: 24px !important;
}
.pl_24 {
    padding-left: 24px !important;
}
.pr_24 {
    padding-right: 24px !important;
}
.mt_24 {
    margin-top: 24px !important;
}
.mb_24 {
    margin-bottom: 24px !important;
}
.ml_24 {
    margin-left: 24px !important;
}
.mr_24 {
    margin-right: 24px !important;
}
.padding-12, .padding_12 {
    padding: 12px;
}
.margin-12, .margin_12 {
    margin: 12px;
}
.padding-20, .padding_20 {
    padding: 20px;
}
.margin-20, .margin_20 {
    margin: 20px;
}
.line_height_1 {
    line-height: 1;
}
.line_height_14 {
    line-height: 1.4;
}
.line_height_16 {
    line-height: 1.6;
}
.line_height_18 {
    line-height: 1.8;
}
.line_height_2 {
    line-height: 2;
}
.line_height_4 {
    line-height: 4;
}
.line_height_6 {
    line-height: 6;
}
.primary {
  color: #1890ff !important;
}
.primary_bg {
  background-color: #1890ff !important;
}
.primary_100, .primary-100 {
  color: rgba(24, 144, 255, 0.1) !important;
}
.primary_100_bg, .primary-100-bg {
  background: rgba(24, 144, 255, 0.1) !important;
}
.primary_border_color, .primary-border-color {
  border-color: #1890ff !important;
}
.bg-success-color, .bg_success_color, .success_bg {
  background-color: #52c41a !important;
}
.success_color, .success {
  color: #52c41a !important;
}
.success_border_color, .success-border-color {
    border-color: #52c41a !important;
}
.warn_color, .warn {
  color: #fa8c16 !important;
}
.bg_warn_color {
  background : #fa8c16 !important;
}
.warn_border_color, .warn-border-color {
    border-color : #fa8c16 !important;
}
.danger_border_color, .danger-border-color {
    border-color : #ff4d4f !important;
}
.danger_color, .danger, .danger-color {
  color: #ff4d4f !important;
}
.bg_danger_color, .danger_bg, .danger-bg, .danger-bg-color {
  background : #ff4d4f !important;
}
.info_color {
    color: #8e8e93 !important;
}
.border {
    border: 1px solid #e8e8e8;
}
.border_top, .border-top {
    border-top: 1px solid #e8e8e8;
}
.border_right, .border-right {
    border-right: 1px solid #e8e8e8;
}
.border_bottom, .border-bottom {
    border-bottom: 1px solid #e8e8e8;
}
.border_left, .border-left {
    border-left: 1px solid #e8e8e8;
}
.box_shadow {
    box-shadow: 0 0 12px rgba(0, 0, 0, .12);
}
/* common end */
/* // flex布局兼容写法  start */
.flex {
  display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box; /* Firefox 17- */
  display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex; /* Firefox 18+ */
  display: -ms-flexbox; /* IE 10 */
  display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
}
.flex_col {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex_col_reverse {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
/* flex换行 */
.flex_wrap {
  /* 09版 */
  /*-webkit-box-lines: multiple;*/
  /* 12版 */
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
/* 主轴居中 */
.justify_content_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
/* 主轴两端对齐 */
.justify_content_between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
/* 主轴end对齐 */
.justify_content_end {
  -webkit-box-pack: end;
  -moz-justify-content: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
/* 侧轴居中 */
.align_items_center{
  -webkit-box-align: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}
/* 侧轴底部对齐 */
.align_items_end {
  -webkit-box-align: end;
  -moz-align-items: end;
  -moz-align-items: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
/* 侧轴文本基线对齐 */
.align_items_baseline {
  -webkit-box-align: baseline;
  -moz-align-items: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.flex_1 {
	-webkit-flex: 1;        /* Chrome */  
  -moz-box-flex: 1;       /* OLD - Firefox 19- */ 
  /* width: 20%;  */
  -webkit-box-flex: 1;     /* OLD - iOS 6-, Safari 3.1-6 */  
  -ms-flex: 1;             /* IE 10 */  
  flex: 1;                /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
.flex_2 {
	-webkit-flex: 2;        /* Chrome */  
  -moz-box-flex: 2;       /* OLD - Firefox 19- */ 
  /* width: 20%;  */
  -webkit-box-flex: 2;     /* OLD - iOS 6-, Safari 3.1-6 */  
  -ms-flex: 2;             /* IE 10 */  
  flex: 2;                /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
.flex_3 {
	-webkit-flex: 3;        /* Chrome */  
  -moz-box-flex: 3;       /* OLD - Firefox 19- */ 
  /* width: 20%;  */
  -webkit-box-flex: 3;     /* OLD - iOS 6-, Safari 3.1-6 */  
  -ms-flex: 3;             /* IE 10 */  
  flex: 3;                /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
/* // 子元素侧轴的自身对齐方式 */
.align_self_start {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
	align-self: flex-start;
}
.align_self_center {
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
	align-self: center;
  margin: 0 auto;
}
.align_self_end {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
	align-self: flex-end;
}
/*子元素的显示顺序*/
.order_1 {
  -webkit-box-ordinal-group: 1;
  -moz-order: 1;
  -webkit-order: 1;
  order: 1;
}
.order_2{
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}
.order_3{
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}
/* // flex布局兼容写法  end */
